<template>
    <div class="content">
        <img src="../../assets/img/index/page-line.png" alt="" class="top-line">
        <div class="nav">
            <span style="color:rgba(51, 51, 51, .6)">首页</span>
            <span style="color:rgba(51, 51, 51, .6);margin: 0 10px;">></span>
            <span style="color:rgba(51, 51, 51, .6)">要闻动态</span>
            <span style="color:rgba(51, 51, 51, .6);margin: 0 10px;">></span>
            <span>要闻动态</span>
        </div>
        <div class="newList">
            <div class="newItem" v-for="(item,index) in list" @click="goInfo(item)">
                <div class="left">
                    <div class="day">{{ item.createdTime[2] }}</div>
                    <div class="line"></div>
                    <div class="time">{{ item.createdTime[0]+'.'+item.createdTime[1] }}</div>
                </div>
                
                <div class="right">
                    <div class="title">{{item.articleTitle}}</div>
                    <div class="info">{{ item.articleAbstract }}</div>
                </div>
            </div>
        </div>
        <div class="page-num">
            <el-pagination
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
                :pager-count="pagerCount"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>
  
<script>
import {getArticleList} from '@/api'
import {parseTime} from '@/utils'
  export default {
    name: "Trends",
    components: {

    },
    data() {
      return {
        current:0,
        list:[],
        total:0,
        pageSize:10,
        pagerCount:1
      };
    },
    mounted() {
      
      this.getList(1)
    },
    methods: {
        getList(type){
          getArticleList({
              channelId:type,
              pageNum:this.pagerCount,
              pageSize:this.pageSize
          }).then(res=>{
              console.log(res)
              if(res.code==200){
                this.list=res.data
                this.list.forEach((item,index)=>{
                  item.createdTime=parseTime(item.createdTime,'{y}-{m}-{d}')
                  console.log(item.createdTime)
                  item.createdTime=item.createdTime.split('-')

                })
              }
              
          })
        },
        goInfo(row){
            this.$router.push({path:'trendsInfo',query:{id:row.articleId}})
        },
        handleSizeChange(val) {
          console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
          console.log(`当前页: ${val}`);
        }
    }
  };
</script>
<style scoped lang="less">
/deep/ .el-icon-arrow-right:before{
    content:'\e791';
    width: 25px;
    height: 25px;
}
/deep/ .el-icon-arrow-left:before{
    content:'\e792';
    width: 25px;
    height: 25px;
}
/deep/ .el-icon-arrow-left,/deep/ .el-icon-arrow-right{
    width: 38px;
    height: 38px;
    text-align: center;
    line-height: 38px;
}
/deep/ .el-icon-arrow-right:hover{
    color: #9E1913;
}
/deep/ .el-icon-arrow-left:hover{
    color: #9E1913;
}
/deep/ .el-pager li.active{
    // width: 38px;
    // height: 38px;
    background-image: url('../../assets/img/index/page-num.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // background-position:0 -20px ;
    color: #fff;
    
}
/deep/ .el-pager li{
    width: 38px;
    height: 38px;
    text-align: center;
    line-height: 38px;
}
/deep/ .el-pager li:hover{
    color: #9E1913;
}

</style>
<style scoped lang="scss">
.content{
    width: 1400px;
    margin: 0 auto;
    position: relative;

    .nav{
        width: 100%;
        height: 85px;
        font-size: 16px;
        font-family: SimSun;
        font-weight: 400;
        color: #333333;
        position: absolute;
        top: 20px;
    }
    .top-line{
        width: 100%;
        height: 85px;
        margin-top: 15px;
    }
    .newList{
        margin-top: 20px;
        .newItem{
            height: 100px;
            display: flex;
            flex-wrap: nowrap;
            // align-items: center;
            position: relative;
            margin-top: 28px;
            .left{
                width: 81px;
                height: 78px;
                background: url('../../assets/img/index/new-time-no.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                font-size: 18px;
                font-family: SimSun;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                padding: 8px;
                box-sizing: border-box;
                .day{
                    font-weight: 600;
                }
                .line{
                    border-bottom: 1px solid #FFFFFF;
                    margin: 6px 0;
                }
            }
            .right{
                margin-left: 19px;
                .title{
                    margin-top: 8px;
                    font-size: 20px;
                    font-family: FZCuSong-B09;
                    font-weight: 600;
                    color: #333333;
                }
                .info{
                    font-size: 16px;
                    font-family: SimSun;
                    font-weight: 400;
                    color: rgba(51, 51, 51, .6);
                    margin-top: 20px;
                    overflow: hidden;
                    -webkit-line-clamp: 1;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }
            }
        }
        .newItem::after{
            content: '';
            width: 100%;
            height: 6px;
            background-image: url('../../assets/img/index/new-line.png');
            position:absolute;
            bottom: 0;
        }
        .newItem:hover{
            .left{
                background: url('../../assets/img/index/new-time.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            .right{
                .title{
                    color: #9E1913;
                }
            }
        }
    }
    .page-num{
        width: 100%;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-family: SimSun;
        font-weight: 400;
        color: #010101;
    }
}

</style>
  
  